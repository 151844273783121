<script setup lang="ts">
import type { FormInstance } from 'ant-design-vue'
import AsyncIcon from '@/layout/components/menu/async-icon.vue'
// 定义父组件传过来的值
const props = defineProps({
  // 搜索表单
  search: {
    type: Array<TableSearchType>,
    default: () => [],
  },
})

// 定义子组件向父组件传值/事件
const emit = defineEmits(['search'])

// 定义变量内容
const tableSearchRef = ref<FormInstance>()
const state = reactive({
  form: {} as any,
  isToggle: false,
})

// 查询
function onSearch(formEl: FormInstance | undefined) {
  if (!formEl)
    return

  formEl.validate().then(() => {
    emit('search', state.form)
  })
}

// 重置
function onReset(formEl: FormInstance | undefined) {
  if (!formEl)
    return
  formEl.resetFields()
  emit('search', state.form)
}

// 初始化form字段，取父组件search.prop
function initFormField() {
  if (props.search.length <= 0)
    return false
  props.search.forEach(v => (state.form[v.name] = ''))
}

onMounted(initFormField)

// onMounted(setFormItemMargin)

watch(() => state.isToggle, () => {
  setFormItemMargin()
})

onMounted(setFormItemMargin)

watch(() => props.search.length, () => {
  setFormItemMargin()
})

function setFormItemMargin() {
  const formItems = document.querySelectorAll('.ant-form-item') as unknown as HTMLElement[]

  if (!formItems || formItems.length === 0)
    return

  const remnantItems = formItems.length <= 4 ? 0 : formItems.length % 4
  let count = remnantItems
  if (remnantItems === 0)
    count = 4

  let start = 0
  const end = formItems.length

  if (state.isToggle) {
    start = formItems.length - count
  }

  for (let i = 0; i < formItems.length; i++) {
    formItems[i].removeAttribute('mb-0')
  }

  for (let i = start; i < end; i++) {
    formItems[i].setAttribute('mb-0', '')
  }
}

function filterOption(input: string, option: any) {
  return option.label.toLowerCase().includes(input.toLowerCase())
}

const searchValue = ref('')
</script>

<template>
  <a-form ref="tableSearchRef" :model="state.form" :wrapper-col="{ span: 18 }" :label-col="{ span: 6 }">
    <a-row>
      <a-col v-for="(val, key) in search" v-show="key < 3 || state.isToggle" :key="key" :span="6">
        <div v-if="val.type.trim() !== ''">
          <a-form-item
            :label="val.label" :name="val.name"
            :rules="[{ required: val.required, message: `${val.label}不能为空`, trigger: val.type === 'input' ? 'blur' : 'change' }]"
            :colon="false"
          >
            <a-input
              v-if="val.type === 'input'" v-model:value="state.form[val.name]" :placeholder="val.placeholder"
              allow-clear style="width: 100%;"
            />
            <a-date-picker
              v-else-if="val.type === 'date'" v-model:value="state.form[val.name]"
              value-format="YYYY-MM-DD" :placeholder="val.placeholder" style="width: 100%;" :allow-clear="true"
            />
            <a-select
              v-else-if="val.type === 'select'" v-model:value="state.form[val.name]" :options="val.options"
              :placeholder="val.placeholder" style="width: 100%" :allow-clear="true" show-search :filter-option="filterOption"
            />
            <a-tree-select
              v-else-if="val.type === 'tree-select'" v-model:value="state.form[val.name]"
              v-model:search-value="searchValue"
              :field-names="{ children: 'children', value: 'id', label: 'label' }" :tree-data="val.options"
              :placeholder="val.placeholder" style="width: 100%" :allow-clear="true" tree-default-expand-all show-search tree-node-filter-prop="label"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            >
              <template #title="{ value: val, label }">
                <template
                  v-for="(fragment, i) in label
                    .toString()
                    .split(new RegExp(`(?<=${searchValue})|(?=${searchValue})`, 'i'))"
                >
                  <span
                    v-if="fragment.toLowerCase() === searchValue.toLowerCase()"
                    :key="i"
                    style="color: #08c"
                  >
                    {{ fragment }}
                  </span>
                  <template v-else>
                    {{ fragment }}
                  </template>
                </template>
              </template>
            </a-tree-select>
          </a-form-item>
        </div>
      </a-col>
      <a-col :span="6">
        <a-form-item :colon="false">
          <template v-if="search.length > 3" #label>
            <div
              ml-10px flex flex-items-center cursor-pointer class="table-form-btn-toggle"
              @click="state.isToggle = !state.isToggle"
            >
              <span mr-5px>{{ state.isToggle ? '收起' : '展开' }}</span>
              <AsyncIcon :icon="state.isToggle ? 'UpOutlined' : 'DownOutlined'" :is-ant-icon="true" />
            </div>
          </template>
          <div :class="{ 'ml-10px': search.length <= 3 }">
            <a-button type="primary" @click="onSearch(tableSearchRef)">
              查询
            </a-button>
            <a-button ml-10px @click="onReset(tableSearchRef)">
              重置
            </a-button>
          </div>
        </a-form-item>
      </a-col>
    </a-row>
  </a-form>
</template>

<style lang="scss" scoped>
.table-form-btn-toggle {
  white-space: nowrap;
  user-select: none;
  display: flex;
  color: var(--pro-ant-color-primary);
}
</style>
